import React, { CSSProperties } from 'react'
import styled from 'styled-components'

import Theme from '../theme'
import Section from './section/section'
import { Business } from '../utils/constants'

const ContactLink = styled.a<CSSProperties>`
    color: ${props => props.color ? props.color : Theme.palette.primary.light};
    text-decoration: none;
    display: block;
    width: inherit;

    :hover {
        color: ${Theme.palette.primary.dark};
        text-decoration: none;
    }
`

const Icon = styled.i`
    font-size: 36px; 
    margin-right: 20px;
`

const SectionBlock = styled.div`
    display: flex;
    align-items: center;
    margin: 0;
    padding: 20px;
    align-items: center;
`

const AddressLineWrapper = styled.div`
    display: flex; 
    flex-direction: column; 
    justify-content: left;
`

const AddressLine = styled.p`
    padding: 0 !important;
    margin: 0 !important;
    width: 100%;
`

const ContactInfo: React.SFC<{margin?: string, color?: string}> = props => {
    return(
        <Section 
            flexDirection="column" 
            alignItems="start" 
            margin={props.margin ? props.margin : '0 auto 40px auto'}>
            <ContactLink 
                href={`mailto:${Business.email}`}
                color={props.color ? props.color : ''}>
                <SectionBlock>
                    <Icon className="material-icons">email</Icon>
                    {Business.email}
                </SectionBlock>
            </ContactLink>
            <ContactLink 
                margin="20px 0"
                href={`tel:1${Business.phoneNumber}`}
                color={props.color ? props.color : ''}>
                <SectionBlock>
                    <Icon className="material-icons">phone</Icon>
                    {Business.phoneNumber}
                </SectionBlock>
            </ContactLink>
            <ContactLink 
                href="https://goo.gl/maps/sgzEnnEc3mk" 
                target="_blank"
                rel="noopener noreferrer"
                color={props.color}>
                <SectionBlock>
                    <Icon className="material-icons">directions</Icon>
                    <AddressLineWrapper>
                        <AddressLine>201 NW Victoria Dr</AddressLine>
                        <AddressLine>Lees Summit, MO 64086</AddressLine>
                    </AddressLineWrapper>
                </SectionBlock>
            </ContactLink>
        </Section>
    )
}

export default ContactInfo